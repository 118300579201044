import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";

const ScrollTop = () => {
  
  return (
    <button
      id="scroll-btn"
      className="btn btn-rounded bg-grey shadow-blue position-fixed mr-3 text-center align-items-center justify-content-center"
    >
      <FontAwesomeIcon icon={faChevronUp} className="h4 text-white mb-0"/>
    </button>
  );
};

export default ScrollTop;
