import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { smFacebook, smLinkedin, smGithub } from "../../config";
import { FooterIso } from "../../../src/config/index";
const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiNavLinks {
        nodes {
          isLink
          linksList {
            link
            title
            type
          }
          title
        }
      }
    }
  `);
  const { nodes: links } = data.allStrapiNavLinks;
  return (
    <footer className="site-footer section block bg-contrast">
      <div className="container py-4">
        <div className="row gap-y text-left">
          <div className="col-md-10">
            <div className="row">
              {links.map((linkData, index) => (
                <>
                {linkData.title.toLowerCase() !== "products" ? (
                <React.Fragment key={index}>
                  <div className="col-sm-3">
                    <h6 className="py-2 ">
                      {linkData.title}
                    </h6>
                    <nav className="nav flex-column">
                      {linkData.linksList.map((subLinkData, index) => {
                        const { title, link } = subLinkData;
                        return (
                          <Link
                            className={`tagmanager-nav-footer nav-item py-2`}
                            key={index}
                            to={link}
                            title={`${title} page link`}
                            noPrefetch
                          >
                            {title}
                          </Link>
                        );
                      })}
                    </nav>
                  </div>
                </React.Fragment>) : ""}
                </>
              ))}
            </div>
          </div>
          <div className="col-md-2">
            <div className="row">
              <div className="col-md-12 d-flex justify-content-start">
                <h6 className="py-2">
                  Connect With Us
                </h6>
              </div>
              <div className="col-md-12 d-flex justify-content-end">
                <nav className="nav ">
                  <a
                    href={smFacebook}
                    className="tagmanager-facebook-footer btn btn-circle btn brand-facebook mr-2"
                    target="_blank"
                    rel="noreferrer noopener"
                    title={`facebook page link`}
                  >
                    <FontAwesomeIcon icon={faFacebook} className="h4 text-white mb-0" />
                  </a>{" "}
                  <a
                    href={smLinkedin}
                    className="tagmanager-linkedin-footer btn btn-circle btn brand-linkedin mr-2"
                    target="_blank"
                    rel="noreferrer noopener"
                    title={`linkedin page link`}
                  >
                    <FontAwesomeIcon icon={faLinkedin} className="h4 text-white mb-0" />
                  </a>{" "}
                  <a
                    href={smGithub}
                    className="tagmanager-github-footer btn btn-circle btn brand-github"
                    target="_blank"
                    rel="noreferrer noopener"
                    title={`github page link`}
                  >
                    <FontAwesomeIcon icon={faGithub} className="h4 text-white mb-0" />
                  </a>{" "}
                </nav>
              </div>
            </div>
          </div>
        </div>
        <hr className="mt-5" />
        <div className="row small align-items-center">
          <div className="col-md-12 m-auto">
            <p className="mt-2 mb-md-0 text-secondary text-center">
              &copy; {new Date().getFullYear()} Grootan Technologies. All Rights
              Reserved.
            </p>
            <p className="mt-2 mb-md-0 text-secondary text-center">
              This site is best viewed in all resolution with latest version of
              Chrome, Firefox, Safari and Microsoft Edge
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
