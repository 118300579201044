export const formHome =
  "https://forms.zohopublic.com/arvindk/form/HomeContactUs/formperma/qbWuo42O8bxlEvziQjAgWeDb-nU2EFUnRt-OhAd-D2A/htmlRecords/submit";
export const formContactUs =
  "https://forms.zohopublic.com/arvindk/form/GrootanContactForm/formperma/ShSm-lgOmmBazGoIVMNBC2bgQStu6JMCmr5bkSTcAN0/htmlRecords/submit";
export const formJobApplication =
  "https://forms.zohopublic.com/arvindk/form/Recruit/formperma/AND3cFLcytssDWjzIGneLn196m7GdQOJi_YG8F3j5P8/htmlRecords/submit";

//dev site keys
// export const siteKey = "6LdI8bsaAAAAACUE3aGXqvd3H2UEipFegmPNHRIW";
// export const secretKey = "6LdI8bsaAAAAAJi2pdfpBdt1z_NsmFyfq99aWUIm";

//prod site keys
export const siteKey = "6Lda7rkaAAAAAMsEuMfUsWPphd11uQ_-TuB7--Q8";
export const secretKey = "6Lda7rkaAAAAAH7YNy5V7MQU4Ji7QHNm-Ir5nOPW";

export const zohoCareers = "https://careers.grootan.com/jobs/Careers";
export const smFacebook = "https://www.facebook.com/grootantech/";
export const smLinkedin = "https://in.linkedin.com/company/grootan-technologies";
export const smGithub = "https://github.com/grootan";
export const FooterIso = "https://grootan-strapi-uploads.s3.ap-south-1.amazonaws.com/iso_line_38d6885d2d.svg";

export const ISO = "ISO 27001:2022";
export const isoCertifiedText = `${ISO} certified`;


export const metaData = {
  "home": {
    "keyword":
      `identity access and management, ISO, Information security management, ISO certified, ${isoCertifiedText}, QlikVerify, QlikSlides, kyc, ai based kyc, know your customer solutions, authentication, authorisation, 2fa, two factor authentication, multi factor authentication, kobil integration, kobil systems, grootan , grootan technologies, grootan tech, grootan technologies chennai, grootan mobile , oauth 2.0, oauth, security solutions, keycloak, saml, mobile applications, mobile apps, Identity management, identity management system, identity access management, identity & access management, access, security, open id, bots, bot service, temenos, cloud, cloud computing, cloud solutions, identity and access management, iam, iam solutions, keycloak developers, keycloak authenticators, keycloak extensions, keycloak clustering`,
    "description":
      `Grootan is a superior service provider of technology solutions for businesses within a wide range of industries, including financial services, retail, insurance, health care and gaming. It has also acquired ${ISO}, an Information Security Management Certification.`
  },
  "financial": {
    "keyword": "modern financial services, financial services, grootan",
    "description":
      "Every financial organisation is a technology company, where a secure and seamless experience is significant. Grootan offers you modern financial services to do business securely in the connected world."
  },
  "retail": {
    "keyword": "retail, retail solutions, grootan",
    "description":
      "The evolution of the retail industry has played an effective role in today's modern environment. We Grootan understand the evolving trends and offer Optimized Retail Solutions To Resolve All Your Business Needs."
  },
  "insurance": {
    "keyword": "modern insurance services, insurance services, grootan",
    "description":
      "Insurance industries are rapidly developing with a highly competitive market. We Grootan offer modern insurance services to Transform and Accelerate Digital Transformation."
  },
  "healthcare": {
    "keyword":
      "healthcare, advanced healthcare services, healthcare services, grootan",
    "description":
      "Healthcare is one of the most delicate sectors in existence. We Grootan offer Advanced healthcare services that ensure secured outcomes and substantial growth."
  },
  "game": {
    "keyword":
      "advanced game development services, game development services, futuristic Games, grootan",
    "description":
      "Pushing the Boundaries of Your Imagination. We grootan offer the Advanced game development services with Futuristic Games With Global Standards."
  },
  "openid": {
    "keyword":
      " openID solutions, identity and security, grootan, advanced oAuth and openID solutions, oAuth and openID solutions",
    "description":
      "Never compromise with your identity and security. We Grootan offer advanced OAuth and OpenID solutions that add additional protection and control over your online identity."
  },
  "cloud": {
    "keyword": "cloud solutions, grootan.",
    "description":
      "Experience trust and security in the cloud. Grootan offers secured Cloud solutions with seamless and effective security that bring additional business value for you."
  },
  "appDev": {
    "keyword": "app development, grootan, app development services",
    "description":
      "Turning Your Ideas Into Reality. We Grootan offer robust and high secured app development services that will showcase your innovation and make you stand out from others."
  },
  "aiandml": {
    "keyword": "aI & mL, aI & mL services, grootan",
    "description":
      "Create Breakthrough Results with AI & ML. We Grootan offer powerful and innovative AI and ML services to establish your business case."
  },
  "bot": {
    "keyword": "bot solutions, bot Services, grootan",
    "description":
      "Empower Your Digital World. We Grootan offer advanced Bot solutions that can automate digital engagement across and improve customer experience and increase sales."
  },
  "data": {
    "keyword":
      "data Analytics, data Management, advanced data management & analytics services",
    "description":
      "Unlock the Power of Data to Make Faster and Better Decisions. We Grootan offer advanced data management & analytics services, that help business to make significant decisions."
  },
  "whygrootan": {
    "keyword": `grootan, ISO, Information security management, ISO certified, ${isoCertifiedText}`,
    "description":
      `Grootan, a worldwide pioneer in Identity-as-a-Service (IDaaS), and with advanced technology, we turned to announce that we have acquired ${ISO}, an Information Security Management Certification. The name Grootan was inspired by the Marvel character Groot from the film Guardians of the Galaxy. Yes, we appreciate him for his courage, perseverance, kindness, and simplicity.`
  },
  "team": {
    "keyword":
      "identity access and management, kyc, ai based kyc, know your customer solutions, authentication, authorisation, 2fa, two factor authentication, multi factor authentication, kobil integration, kobil systems, grootan , grootan technologies, grootan tech, grootan technologies chennai, grootan mobile , oauth 2.0, oauth, security solutions, keycloak, saml, mobile applications, mobile apps, Identity management, identity management system, identity access management, identity & access management, access, security, open id, bots, bot service, temenos, cloud, cloud computing, cloud solutions, identity and access management, iam, iam solutions, keycloak developers, keycloak authenticators, keycloak extensions, keycloak clustering",
    "description":
      "A bunch of unconventional thinkers who understand your business pre-requites, speak volumes of creativity and deliver customized solutions."
  },
  "career": {
    "keyword":
      "identity access and management, kyc, ai based kyc, know your customer solutions, authentication, authorisation, 2fa, two factor authentication, multi factor authentication, kobil integration, kobil systems, grootan , grootan technologies, grootan tech, grootan technologies chennai, grootan mobile , oauth 2.0, oauth, security solutions, keycloak, saml, mobile applications, mobile apps, Identity management, identity management system, identity access management, identity & access management, access, security, open id, bots, bot service, temenos, cloud, cloud computing, cloud solutions, identity and access management, iam, iam solutions, keycloak developers, keycloak authenticators, keycloak extensions, keycloak clustering",
    "description":
      "GROOTAN is an advanced workplace that lets you heighten up your skills. We empower you to explore, learn & innovate! Start your journey with us."
  },
  "casestudy": {
    "keyword": "grootan, case studies",
    "description":
      "We Grootan have a proven experience in providing solutions to a broad range of industries. Here we have mentioned a few case studies on how we deliver the power of digital in measurable ways."
  },
  "blog": {
    "keyword": "grootan, grootan blog, it solutions",
    "description":
      "Grootan’s Blog keeps you up to date about many different topics around IT solutions for enterprise companies and mid-size businesses."
  },
  "opensource": {
    "keyword": " grootan, compact solutions",
    "description":
      "We Grootan would like to share our compact solutions with the developer community, which makes our lives a teensy bit easier, every other day. "
  }
};
